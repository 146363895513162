<template>
  <v-dialog v-model="showModalDeleteEtiqueta" width="480px" persistent lazy scrollable>
    <v-card>
      <v-toolbar color="error" card dark>
        <v-toolbar-title>Eliminar Etiqueta de Producto</v-toolbar-title>
        <v-spacer />
        <v-btn icon dark @click="replaceShowModalDeleteEtiqueta({ status: false })">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <center>
        <p style="padding:15px 0 0;">¿Está seguro de eliminar la etiqueta?</p>
        <template v-if="etiqueta">
          <p> 
            <strong>Etiqueta: </strong>{{ etiqueta.etiqueta.nombre }}
          </p>
          
        </template>
      </center>
      <v-divider />
      <div class="my-3 text-xs-center">
        <v-btn
          :disabled="processingDelete"
          :loading="processingDelete"
          color="error"
          @click="launchDelete"
        >
          Eliminar
        </v-btn>
        <v-btn
          :disabled="processingDelete"
          @click="replaceShowModalDeleteEtiqueta({ status: false })"
        >
          Cancelar
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      processingDelete: false,
    };
  },

  computed: {
    ...mapState({
      showModalDeleteEtiqueta: (state) => state.productos.showModalDeleteEtiqueta,
      etiqueta: (state) => state.productos.etiqueta,
    }),
  },

  watch: {
    showModalDeleteEtiqueta(newValue, oldValue) {
      if (!newValue) {
        this.replaceCurrentEtiqueta({ etiqueta: null });
        return false;
      }
    },
  },

  methods: {
    ...mapActions({
      replaceShowModalDeleteEtiqueta: "productos/replaceShowModalDeleteEtiqueta",
      replaceCurrentEtiqueta: "productos/replaceCurrentEtiqueta",
      deleteEtiqueta: "productos/deleteEtiqueta",
      getEtiquetas: "productos/getEtiquetas",
    }),

    launchDelete() {
      this.processingDelete = true;
      this.deleteEtiqueta({ etiquetaId: this.etiqueta.id })
        .then((response) => {
          this.processingDelete = false;
          this.replaceShowModalDeleteEtiqueta({ status: false });
          this.getEtiquetas({ productoId: this.$route.params.id });
        })
        .catch(() => {
          this.processingDelete = false;
        });
    },
  },
};
</script>
